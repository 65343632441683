import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoutes";
import {Login} from "../pages/auth/Login";
import {RegisterAccount} from "../pages/auth/Signup";
import Home from "../pages/home/Home";
import Scanner from "../pages/home/Scanner";
import AllScans from "../pages/home/AllScans";
import { Reset } from "../pages/auth/reset";

export default () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="dashboard" element={<Home />} />
          <Route path="allscans" element={<AllScans />} />
          <Route path="scanner" element={<Scanner />} />
          <Route path="trades" element={<Scanner />} />
          <Route path="analysis" element={<Scanner />} />
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<RegisterAccount />} />
        <Route path="/reset" element={<Reset />} />

      </Routes>
    </Router>
  );
};
