import { useLocation, useNavigate } from "react-router-dom";
import { sidebarItems } from "../../constants/data/data";

const Sidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="p-4 bg-white h-screen !z-10  ">
      {sidebarItems.map((item: any) => (
        <div
          className={` my-4 p-2 rounded cursor-pointer text-gray-500 text-sm font-light ${
            pathname.toLowerCase().includes(item.name.toLowerCase()) ? "bg-[#F2F2FD]   font-semibold" : ""
          }`}
          onClick={() => {
            navigate(item.url);
          }}
        >
          {item.name} 
        </div>
      ))}
    </div>
  );
};

export { Sidebar };
