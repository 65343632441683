import { Fragment, useEffect, useState } from "react";
import { LiaExchangeAltSolid } from "react-icons/lia";
import { PiClockFill } from "react-icons/pi";
import { CiCalendarDate, CiCircleInfo, CiEdit } from "react-icons/ci";
import { Menu, Transition } from "@headlessui/react";
import { IoMdEye, IoMdMore } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { useStateSetter } from "../../hooks/statehooks/UseStateSettersHook";
import { toast } from "react-toastify";
import { onDeleteSaveScan } from "../../services/user";
import { Button } from "../forms";

interface ScanItemDTO {
  pairs: string [];
  details: string [];
}
export default ({item, refresh}:any) => {
  const [jsonItem, setjsonItem] = useState<ScanItemDTO>();
const {setLoading} =useStateSetter()


   const deleteScan = async ()=>{
    setLoading(true)
try{

var res = await onDeleteSaveScan(item.id)
toast.success("Deleted!")
refresh()

}

catch(e:any){
console.log(e)
toast.error(e)

}
finally{

    setLoading(false)

}


   }

  useEffect(() => {
    setjsonItem(JSON.parse(item.content?item.content:"{}"));
  },[]);
  return (
    <div className="rounded bg-white px-4 flex justify-between  gap-3 py-2 h-[200px]">
   <div className="flex flex-col ">
      <p className="text-lg font-bold text-gray-700">
      <LiaExchangeAltSolid className="inline mx-1" />  {item.market}
        <span className="text-sm">
        { "  "}  {jsonItem?.pairs.splice(0, 3).map((pair: any) =><span className="bg-blue-100 p-1 rounded mx-1 font-light"> {pair}</span>)}
        </span>
      </p>
      <p className="text-sm font-light text-gray-700"><CiCalendarDate className="inline mx-1" />{item?.date}</p>
      <p className="text-sm font-light text-gray-700"><PiClockFill className="inline mx-1" />{item?.timezone}</p>

      <p className=" mt-2 w-full text-xs overflow-hidden text-gray-500 font-light text-justify "><CiCircleInfo className="inline mx-1"/>{jsonItem?.details[0]}</p>
   

      <Button
      width={200}
    outlined
    text={
      <>
      
      <IoMdEye className="inline" /> View details
      </>
    }
    onBtnClick={ async()=>{

  
    }}
  /> 
        </div>
        
        <Menu as="div" className={`relative my-2 `}>
        <Menu.Button className="   w-full       ">
          <div className="flex flex-items items-center  w-full gap-4">
            <div className=" flex justify-between w-full  items-center p-1">
              <IoMdMore size={20} className="text-textbg " />
            </div>

            <div className="flex items-center"></div>
          </div>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            onClick={() => {
              }}
            className="origin-top-left z-10 absolute left-0 mt-2 rounded-sm shadow-md p-1 bg-white w-full  max-h-[200px] min-w-[150px]   overflow-x-hidden overflow-y-scroll ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
           
            <Menu.Item>
              <p
                onClick={() => {deleteScan()}}
                className=" text-xs font-light w-full text-black p-3 m-2 cursor-pointer truncate hover:bg-customGray "
              >
                <MdDelete onClick={()=>{
                    console.log("jkj")
 }} className="inline" /> Delete
              </p>
            </Menu.Item>
           
           
          </Menu.Items>
        </Transition>
      </Menu>
   
    </div>
  );
};
