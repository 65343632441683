import { Link, Navigate, useNavigate } from "react-router-dom"
import { reg_doodle, logo } from "../../constants/imports"
import { Button, InputField } from "../../components/forms"
import { emailReg, textReg } from "../../utils/regex"
import { useForm } from "react-hook-form"
import { TfiClose } from "react-icons/tfi";
import { useStateGetter } from "../../hooks/statehooks/UseStateGettersHook"
import { useStateSetter } from "../../hooks/statehooks/UseStateSettersHook"
 import { toast } from "react-toastify"
import { SiMinutemailer } from "react-icons/si";


 
const Reset=()=>{

const {handleSubmit, control, watch}=useForm()
const {isTabletOrMobile}=useStateGetter()
 
const watchedFields = watch(['email']);
const navigate=useNavigate()
 const { setLoading} = useStateSetter();
  
 
const areFieldsFilled = watchedFields.every(field => (field !== '' && field !==undefined))
const GetOTP = (props:any) => (
  <div className=" flex flex-col justify-center items-center">
    <p className=" font-medium text-textbg  my-2">
      {" "}
      OTP Verification required{" "}
    </p>
    <p className="font-light text-textbg  text-xs text-center">
      {" "}
    We will send a One Time Password (OTP)
      to  {props.email}
    </p>
    <div className=" gap-6 w-full flex ">
      <Button
        outlined
        disabled={false}
        text="Cancel"
        onBtnClick={() => {
         
        }}
      />
      <Button
        disabled={false}
        text="Get OTP"
        onBtnClick={async () => {

        //   setLoading(true);
        //   var postdata = { email: props.email };
        //    var res =await onGetOtp(postdata)
        //   setLoading(false);
        //   toast("OTP sent to your email")
        //  navigate("/verifyreset");
        //   setShowModal(false);

        }}
      />
    </div>{" "}
  </div>
);
    const onSubmit=(data:any)=>{
   

    }

 
    return(<div className="p-4 md:p-10 w-screen h-screen bg-customGray  flex flex-col md:grid md:grid-cols-2 items-center justify-center">
      {!isTabletOrMobile && (
        <div className="px-30 py-10 flex flex-col gap-10 justify-center items-center ">
          <img className="w-[173px] mb-4 " src={logo} />
          <img className="w-[330px]  " src={reg_doodle} />
        </div>
      )}
        <div className="bg-white  w-[100%] md:w-[458px] p-6  md:min-h-[80vh]  md:mx-20 md:py-2 md:px-20 shadow flex flex-col  justify-center  ">
        {isTabletOrMobile && (
          <img className="w-[173px] self-center my-12  " src={logo} />
        )}

        <TfiClose className="m-4"
        
        onClick={()=>{

          navigate(-1)
        }}
        size={30}/>


            <p className="font-bold text-textbg text-[24px] ">Reset Password</p>
            <small className="font-light text-textbg text-xs ">Kindly provide your TradetimeScanner's registered email address</small>
         <div className=" my-4">
 
<InputField
                  name="email"
                  title="Email"
                  placeholder="Enter Email Address"
                  control={control}
                  rules={{
                    required: "Email Address is required",
                    pattern: {
                      value: emailReg,
                      message: "Invalid Email Address",
                    },
                  }}
                />
        
          {/* <div className="w-full">
<Link className="text-primary" to='/'>
          Create an account </Link>
          </div> */}
            
           
          <Button disabled={!areFieldsFilled} text="Reset" onBtnClick={handleSubmit(onSubmit)} />
        </div>
      
        </div>
          </div>)
}

export{Reset}