import { Navigate, Outlet } from "react-router-dom";
import Navbar from "../components/generic/Navbar";
import { Sidebar } from "../components/generic/Sidebar";
import Home from "../pages/home/Home";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../utils/authentication";

const ProtectedRoute = () => {



  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 644px)",
  }); 

  const auth = useAuth();
  return auth ? (
    <div className="w-screen   bg-[#f3f3f3] h-screen flex">
      <Navbar />
    { isDesktopOrLaptop&& <div className="w-[20vw] relative top-16">
        <Sidebar />
      </div>}
      <div className={` ${ isDesktopOrLaptop ?"w-[80vw]" :"w-[100vw]"} relative top-[70px] px-3 md:px-6`}>
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};
export default ProtectedRoute;
