import { Link, useNavigate } from "react-router-dom";
import { reg_doodle, logo } from "../../constants/imports";
import { Button, InputField } from "../../components/forms";
import { emailReg, numReg, textReg } from "../../utils/regex";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useStateGetter } from "../../hooks/statehooks/UseStateGettersHook";
import { IoMdClose } from "react-icons/io";
import { useStateSetter } from "../../hooks/statehooks/UseStateSettersHook";
import { FaCheck, FaInfo } from "react-icons/fa";
import { toast } from "react-toastify";
import { onCreateUser } from "../../services/user";

const RegisterAccount = () => {
  const { handleSubmit, control, watch } = useForm();
  const { isTabletOrMobile } = useStateGetter();
  const navigate = useNavigate();
  const [confpass, setconfpass] = useState("");
  const [pass, setpass] = useState("");
  const watchedemail = watch(["email"]);

  const watchedFields = watch([
    "firstname",
    "lastname",
  
    "email",
    "password",
    "conpassword",
  ]);

  const { setLoading } = useStateSetter();
  const wacthpasswordfild = watch(["password"]);
  const wacthconfpasswordfild = watch(["conpassword"]);

  const isvalidpassword = wacthpasswordfild.every(
    (pass) =>
      !(pass?.length < 6) &&
      /\d/.test(pass) &&
      /[!@#$%^&*(),.?":{}|<>]/.test(pass)
  );
  const areFieldsFilled = watchedFields.every(
    (field) =>
      field !== "" && field !== undefined && isvalidpassword && confpass == pass
  );

  useEffect(() => {
    setconfpass(wacthconfpasswordfild[0]);
  }, [wacthconfpasswordfild]);

  useEffect(() => {
    setpass(wacthpasswordfild[0]);
  }, [wacthpasswordfild]);

  const onchange = () => {
    console.log(areFieldsFilled);
  };
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const postdata = {
        fullName: `${data.firstname} ${data.lastname}`,

        email: data.email,

        password: data.password,
      };

      const responsse = await onCreateUser(postdata);

      toast.success("Acoount registered");
      navigate("/login");
      console.log(responsse);
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      toast.error(error);
    }
    setLoading(false);
  };

  return (
    <div className="p-4 md:p-10 w-screen h-screen bg-customGray  flex flex-col md:grid md:grid-cols-2 items-center justify-center">
      {!isTabletOrMobile && (
        <div className="px-30 py-10 flex flex-col gap-10 justify-center items-center ">
          <img className="w-[173px] mb-4 " src={logo} />
          <img className="w-[330px]  " src={reg_doodle} />
        </div>
      )}
      <div className="bg-white  w-[100%] md:w-[458px] p-6   overflow-y-scroll h-screen pb-20 overflow-x-hidden  md:mx-20 md:py-4 md:px-20 shadow flex flex-col  ">
        {isTabletOrMobile && (
          <img className="w-[173px] self-center my-12  " src={logo} />
        )}

        <p className="font-bold text-textbg text-[27px] md:text-[24px]">
          Create Account
        </p>
        <small className="font-medium text-textbg text-sm md:text-xs">
          Already have an account?{" "}
          <Link className="text-primary" to="/">
            Login{" "}
          </Link>
        </small>

        <div className=" my-2">
          <InputField
            name="firstname"
            title="First name"
            placeholder="First name"
            control={control}
            rules={{
              required: "First name is required",
              pattern: {
                value: textReg,
                message: "Invalid name",
              },
            }}
          />
          <InputField
            name="lastname"
            title="Last name"
            placeholder="Last name"
            control={control}
            rules={{
              required: "Last name is required",
              pattern: {
                value: textReg,
                message: "Invalid name",
              },
            }}
          />
          {/* <InputField
            name="phone"
            title="Phone number"
            placeholder="Enter phone number"
            control={control}
            rules={{
              required: "Phone number is required",
              pattern: {
                value: numReg,
                message: "Invalid phone number",
              },
            }}
          /> */}
          <InputField
            name="email"
            title="Email Address"
            placeholder="Enter Email Address"
            control={control}
            rules={{
              required: "Email Address is required",
              pattern: {
                value: emailReg,
                message: "Invalid Email Address",
              },
            }}
          />
          <InputField
            name="password"
            title="Password"
            placeholder="Enter Your Password"
            control={control}
            rules={{
              required: "Password is required",
            }}
            type={"password"}
          />
          <InputField
            name="conpassword"
            title="Confirm Password"
            placeholder="Confirm Your Password"
            control={control}
            rules={{
              required: "Password is required",
            }}
            type={"password"}
          />
          {pass && (
            <div className=" p-3 m-2">
              <p className="text-xs m-1">
                {!(pass ? pass?.length < 6 : true) ? (
                  <FaCheck className="inline text-green-400" />
                ) : (
                  <IoMdClose className=" text-red-500 inline" />
                )}{" "}
                6 characters minimum
              </p>

              <p className="text-xs m-1">
                {!/\d/.test(pass) ? (
                  <IoMdClose className=" text-red-500 inline" />
                ) : (
                  <FaCheck className="inline text-green-400" />
                )}{" "}
                Contains a number
              </p>

              <p className="text-xs m-1">
                {!/[!@#$%^&*(),.?":{}|<>]/.test(pass) ? (
                  <IoMdClose className=" text-red-500 inline" />
                ) : (
                  <FaCheck className="inline text-green-400" />
                )}{" "}
                Atleast one special character
              </p>
            </div>
          )}
          {!(confpass == pass) && (
            <p className="text-xs text-center text-red-500">
              {" "}
              <IoMdClose className=" text-red-500 inline" /> Passwords don't
              match
            </p>
          )}
          <Button
            disabled={!areFieldsFilled}
            text="Create"
            onBtnClick={handleSubmit(onSubmit)}
          />
          <div className=" w-full flex justify-center items-center">
            <small className=" text-textbg font-light text-sm md:text-xs w-full text-center ">
              By creating an account you agree to our{" "}
              <Link className="text-primary" to="/">
                Terms of use & Privacy policy
              </Link>
            </small>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export { RegisterAccount };
