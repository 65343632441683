import { CiTimer } from "react-icons/ci";
import { Button } from "../../components/forms";
import { MdClearAll, MdOutlineBookmarkAdd } from "react-icons/md";
import { IoMdQrScanner } from "react-icons/io";
import { GoHistory } from "react-icons/go";
import ScanItem from "../../components/generic/ScanItem";
import { onGetSaveScan } from "../../services/user";
import { useEffect, useState } from "react";
import { useStateSetter } from "../../hooks/statehooks/UseStateSettersHook";
import { useNavigate } from "react-router-dom";
import { useStateGetter } from "../../hooks/statehooks/UseStateGettersHook";
import EmptyList from "../../components/generic/EmptyList";

export default () => {
  const navigate = useNavigate();

  const { setLoading } = useStateSetter();
  const { authuser } = useStateGetter();
  const [savedscan, setsavedscan] = useState([]);

  const getuserSaved = async () => {
    let postdata = {
      userid: authuser.id,
    };

    setLoading(true);
    var res = await onGetSaveScan(postdata);
    setLoading(false);

    setsavedscan(res);
  };

  useEffect(() => {
    getuserSaved();
  }, []);
  return (
    <div className="w-full flex  flex-col">
      <div className="flex justify-between ">
        <p className="font-semibold text-gray-600  flex justify-center items-center">
          <GoHistory className="text-gray-400 mx-2 inline  " />
          All saved scans
        </p>
        <div className="w-[40%] self-end p-6 flex gap-2  justify-center items-center">
          {/* <Button
    outlined
    text={
      <>
      
        <MdClearAll className="inline" /> View
      </>
    }
    onBtnClick={ async()=>{

  
    }}
  /> */}

          <Button
            width={200}
            text={
              <>
                {" "}
                <IoMdQrScanner className="inline" /> Scan now{" "}
              </>
            }
            onBtnClick={() => {
              navigate("/scanner");
            }}
          />
        </div>
      </div>

     
     
  { savedscan.length != 0 ?  
   <div className="px-10 grid grid-cols-1 md:grid-cols-2 gap-4 overflow-y-scroll h-[70vh] py-2">
        { 
          savedscan?.map((item: any) => (
            <ScanItem refresh={getuserSaved} item={item} />
          ))
          }
      </div>:<EmptyList/>}


    </div>
  );
};
