import { useForm } from "react-hook-form";
import { InputField, SelectField } from "../../../../../components/forms";
import { ListPicker } from "../../../../../components/generic/ListPicker";
import { useState } from "react";
import { usePickerhook } from "../../../../../hooks/usePickerhook";
import Tooltip from "../../../../../components/generic/Tooltip";
import { NavigateBtns } from "../../../../../components/generic/NavigateBtns";
import { useStateSetter } from "../../../../../hooks/statehooks/UseStateSettersHook";
import { useStateGetter } from "../../../../../hooks/statehooks/UseStateGettersHook";
import timezones from "../../../../../constants/data/timezones.json";

export default ({ nextStep, previousStep }: any) => {
  const TimeZoneselecthook = usePickerhook();
  const { setScandetails } = useStateSetter();
  const { scandetails, NextStep, PrevStep } = useStateGetter();
  const { control, getValues,watch } = useForm();

 var  fields =watch(["starttime","stoptime"])
 
  return (
    <div>
      <div className="border border-1 p-4 mt-4 border-gray-300 rounded">
      
      
      <div className="flex  flex-col md:flex-row gap-4">
         <InputField
          name="starttime"
          title="Start time"
          placeholder={"Start time"}
          type="time"

          tooltip={  <Tooltip
            title="Start time"
            content="Select the time you want to start trading" 
          />}
          control={control}
        />
           <InputField
          name="stoptime"
          title="Stop time"
          placeholder={"Stop time"}
          type="time"
          tooltip={  <Tooltip
            title="Stop time"
            content="Select the time you want to stop trading" 
          />}
          control={control}
        />

</div> 
      </div>

      <NavigateBtns
        shownext
        showprev
        actionPrev={previousStep}
        nextCondition={!fields.some((it)=>it==undefined) }
        actionNext={() => {
          setScandetails({ starttime: getValues("starttime") ,endtime: getValues("stoptime")});
          NextStep();
        }}
      />
    </div>
  );
};
