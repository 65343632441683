import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { FaCheck } from "react-icons/fa";
import { IoMdClose, IoMdEye, IoMdEyeOff } from "react-icons/io";
import { MdCancel } from "react-icons/md";

interface InputFieldProps {
  control: any;
  name: string;
  defaultvalue?: string;
  rules?: object;
  isDisabled?:boolean;
  placeholder: string;
  secureTextEntry?: boolean;
  tooltip?:JSX.Element,
  title?: string;
  type?: any;
  numberOfLines?: number;
  maxLength?: number;
  showCount?: boolean;
  isTextArea?: boolean;
  style?: any;
  contClass?: any;
}

const InputField: React.FC<InputFieldProps> = ({
  control,
  name,
  isDisabled,
  rules = {},
  placeholder,
  title,
  type,
  tooltip,
  defaultvalue,
  maxLength,
  showCount,
  isTextArea,
  style,
  contClass,
}) => {
  const [inptype, settype] = useState(type);
  const [passvisible, setpassvisible] = useState(false);
  const [focused, setfocused] = useState(false);
 
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultvalue}
      rules={rules}
      render={({
        field: { value = defaultvalue, onChange },
        fieldState: { error },
      }) => (
        <div className={contClass ? contClass : ""}>
          {title && (
            <div className="flex items-center justify-between w-[200px]">
              <p className="text-xs font-medium m-2 text-textbg flex justify-center items-center">{title}      {tooltip}</p>
              <div>
                {showCount && (
                  <p className="text-xs">{`${
                    value?.length > 0 ? value?.length : 0
                  }${maxLength ? "/" + maxLength : ""}`}</p>
                )}
              </div>

         
            </div>
          )}

          {isTextArea ? (
            <textarea
              value={value}
              placeholder={placeholder}
              onChange={(text) => {
                
                onChange(text);
              }}
              className={`block bg-white text-xs py-3 px-4 custom-outline rounded-sm w-full shadow-xs border ${
                error ? "border-red-500" : "border-gray-200"
              }`}
              style={style}
              maxLength={maxLength || undefined}
              rows={3}
            ></textarea>
          ) : (
            <div
              className={`flex justify-center items-center border-2 ${
                focused ? " border-primary shadow" : " border-gray-200 shadow "
              }  bg-white rounded-lg `}
            >
              <input
                type={inptype || "text"}
                value={value}
                disabled={isDisabled}
                id={name}
                onFocus={() => {
                  setfocused(true);
                }}
                onBlur={() => {
                  setfocused(false);
                }}
                placeholder={placeholder}
                onChange={(text) => {
                  onChange(text);
              
                }}
                style={style}
                className={` block bg-white text-xs py-3 custom-outline	px-4 rounded-lg   w-full shadow-xs text-textbg ${
                  error ? "border-red-500" : ""
                }`}
                maxLength={maxLength || undefined}
              />
              {type == "password" &&
                (passvisible ? (
                  <IoMdEye
                    onClick={() => {
                      settype("text");
                      if (inptype == "password") {
                        settype("text");
                      } else {
                        settype("password");
                      }
                      setpassvisible(!passvisible);
                    }}
                    size={20}
                    className=" text-textbg inline m-1 "
                  />
                ) : (
                  <IoMdEyeOff
                    onClick={() => {
                      settype("text");
                      if (inptype == "password") {
                        settype("text");
                      } else {
                        settype("password");
                      }
                      setpassvisible(!passvisible);
                    }}
                    size={20}
                    className=" text-textbg inline m-1 "
                  />
                ))}
            </div>
          )}

          {error && (
            <span className="text-[#ff0000] text-xs self-stretch">
              {error.message || "Error"}
            </span>
          )}
        </div>
      )}
    />
  );
};

export default InputField;
