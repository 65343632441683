import { MdDashboard } from "react-icons/md";
import { RiExchangeFundsFill } from "react-icons/ri";
import { FaTelegramPlane } from "react-icons/fa";

const sidebarItems = [
  { name: "Dashboard", title:"/", url: "/dashboard", icon: <MdDashboard /> },
  { name: "Trade", url: "/", title:"trades" , icon: <RiExchangeFundsFill /> },
  { name: "Analysis", url: "/", title:"analysis", icon: <MdDashboard /> },
  { name: "Join Telegram", url: "/", title:"telegram", icon: <FaTelegramPlane /> },
   
];


export{sidebarItems}